.App {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.App h1 {
  font-size: 20px;
}

.App .Generator {
  width: 500px;
  box-shadow: 8px 8px 16px 0 rgba(0,0,0,.2);
  border-radius: 10px;
  overflow: hidden;
}

.App .Generator .Title {
  background-color: #3F51B5;
  color: white;
  padding: 5px;
}

.App .Generator .Input {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.App .Generator .Input textarea {
  border: none !important;
  width: calc(100% - 4px) !important;
  height: auto;
  text-align: center;
}

.App .Generator .Input button {
  border: none;
  padding: 10px;
  background-color: #3F51B5;
  color: white;
  cursor: pointer;
  fill: white;
}

.App .Generator .Output {
  display: flex;
  flex-direction: column;
  gap: 10px;
}