body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Login styling */
div[data-amplify-authenticator] {
  background: none;
  background-color: none;
}

.amplify-tabs {
  display: none !important;
}

.amplify-label {
  text-align: center !important;
  color: rgb(255, 255, 255) !important;
}

.amplify-button {
  background-color: none !important;
  background: none !important;
  color: white !important;
  border: 0.5px solid rgba(255, 255, 255, 0.6) !important;
}

.amplify-button:hover {
  color: black !important;
  background-color: rgb(255, 255, 255) !important;
}

div[data-amplify-router] {
  border-radius: 10px !important;
  border: none !important;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05), 0 0 10px rgba(0, 0, 0, 0.2) !important;
  background: none !important;
}

.amplify-input {
  color: white !important;
  border: 0.5px solid rgba(255, 255, 255, 0.6) !important;
}

.amplify-input:hover {
  background-color: black !important;
  border: 1px solid white !important;
}

.amplify-input::placeholder {
  color: white !important;
}